import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index.js'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        requiresAuth: true // UPORABNIKU NI TREBA BITI PRIJAVLJEN
        // PRESTAVI NA TRUE

        // roles: ['admin'] // dovolimo samo adminu vpogled na to stran
        // roles: ['user', 'superuser'] // dovoimo samo userju in superuserju
        // če pustimo prazno, se roles ne upošteva
      }
    },
    {
      path: '/clients',
      name: 'clients',
      component: () => import('@/views/Clients/Clients.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/edit_client/:client_id',
      name: 'edit_client',
      component: () => import('@/views/Clients/EditClient.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/client-users',
      name: 'client-users',
      component: () => import('@/views/ClientUser/ClientUsers.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/cash-registers',
      name: 'cash_registers',
      component: () => import('@/views/CashRegister/AdoptedCashRegisters.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/cash-register',
      name: 'cash_register',
      component: () => import('@/views/CashRegister/CashRegister.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/no-clients',
      name: 'no-clients',
      component: () => import('@/views/NoClients.vue'),
      meta: {
        layout: 'full',
        requiresAuth: true
      }
    },

    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full'
      }
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full'
      }
    },
    {
      path: '*',
      redirect: 'error-404'
    }
  ]
})

router.beforeEach((to, from, next) => {
  if (!store.getters['user/loggedIn'] && to.path !== '/login') {
    return next('/login')
  } else if (store.getters['user/loggedIn'] && to.path === '/login') {
    return next('/')
  }

  // če stran, na katero želimo iti vsebuje meta podatek requiresAuth
  // in iz authService zvemo, da user ni prijavljen, ga pošljemo na login
  if (to.meta.requiresAuth && !store.getters['user/loggedIn']) {
    return next('/login')
  }

  // če meta vsebuje array roles (npr ['user', 'superuser'] ali ['admin'])
  // pogledamo v vuex store, če ima prijavljen uporabnik ta role
  if (to.meta.roles) {
    if (to.meta.roles.includes(store.state.user.userData.type)) {
      return next() // z next() spustimo dalje
    }
    return next('/login')
  }

  next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
